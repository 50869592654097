export const CREATE_PROJECT_REQUEST = 'CREATE_PROJECT_REQUEST';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_FAILURE = 'CREATE_PROJECT_FAILURE';

export const EDIT_PROJECT_REQUEST = 'EDIT_PROJECT_REQUEST';
export const EDIT_PROJECT_SUCCESS = 'EDIT_PROJECT_SUCCESS';
export const EDIT_PROJECT_FAILURE = 'EDIT_PROJECT_FAILURE';

export const DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAILURE = 'DELETE_PROJECT_FAILURE';

export const LOAD_PROJECT_REQUEST = 'LOAD_PROJECT_REQUEST';
export const LOAD_PROJECT_SUCCESS = 'LOAD_PROJECT_SUCCESS';
export const LOAD_PROJECT_FAILURE = 'LOAD_PROJECT_FAILURE';

export const GET_ONE_PROJECT_REQUEST = 'GET_ONE_PROJECT_REQUEST';
export const GET_ONE_PROJECT_SUCCESS = 'GET_ONE_PROJECT_SUCCESS';
export const GET_ONE_PROJECT_FAILURE = 'GET_ONE_PROJECT_FAILURE';

export const SELECT_SUBJECT_REQUEST = 'SELECT_SUBJECT_REQUEST';
export const SELECT_SUBJECT_SUCCESS = 'SELECT_SUBJECT_SUCCESS';
export const SELECT_SUBJECT_FAILURE = 'SELECT_SUBJECT_FAILURE';

export const SEARCH_PROJECT_REQUEST = 'SEARCH_PROJECT_REQUEST';
export const SEARCH_PROJECT_SUCCESS = 'SEARCH_PROJECT_SUCCESS';
export const SEARCH_PROJECT_FAILURE = 'SEARCH_PROJECT_FAILURE';

export const SEARCH_SUBJECT_REQUEST = 'SEARCH_SUBJECT_REQUEST';
export const SEARCH_SUBJECT_SUCCESS = 'SEARCH_SUBJECT_SUCCESS';
export const SEARCH_SUBJECT_FAILURE = 'SEARCH_SUBJECT_FAILURE';

export const SEARCH_SCAN_REQUEST = 'SEARCH_SCAN_REQUEST';
export const SEARCH_SCAN_SUCCESS = 'SEARCH_SCAN_SUCCESS';
export const SEARCH_SCAN_FAILURE = 'SEARCH_SCAN_FAILURE';

export const EDIT_MYLIST_REQUEST = 'EDIT_MYLIST_REQUEST';
export const EDIT_MYLIST_SUCCESS = 'EDIT_MYLIST_SUCCESS';
export const EDIT_MYLIST_FAILURE = 'EDIT_MYLIST_FAILURE';

export const LOAD_PROJECT_VISIT_REQUEST = 'LOAD_PROJECT_VISIT_REQUEST';
export const LOAD_PROJECT_VISIT_SUCCESS = 'LOAD_PROJECT_VISIT_SUCCESS';
export const LOAD_PROJECT_VISIT_FAILURE = 'LOAD_PROJECT_VISIT_FAILURE';

export const LOAD_SUBJECT_REQUEST = 'LOAD_SUBJECT_REQUEST';
export const LOAD_SUBJECT_SUCCESS = 'LOAD_SUBJECT_SUCCESS';
export const LOAD_SUBJECT_FAILURE = 'LOAD_SUBJECT_FAILURE';

export const LOAD_ONLY_SUBJECT_REQUEST = 'LOAD_ONLY_SUBJECT_REQUEST';
export const LOAD_ONLY_SUBJECT_SUCCESS = 'LOAD_ONLY_SUBJECT_SUCCESS';
export const LOAD_ONLY_SUBJECT_FAILURE = 'LOAD_ONLY_SUBJECT_FAILURE';

export const LOAD_BASELINE_REQUEST = 'LOAD_BASELINE_REQUEST';
export const LOAD_BASELINE_SUCCESS = 'LOAD_BASELINE_SUCCESS';
export const LOAD_BASELINE_FAILURE = 'LOAD_BASELINE_FAILURE';

export const LOAD_VISIT_REQUEST = 'LOAD_VISIT_REQUEST';
export const LOAD_VISIT_SUCCESS = 'LOAD_VISIT_SUCCESS';
export const LOAD_VISIT_FAILURE = 'LOAD_VISIT_FAILURE';

export const LOAD_OVERVIEW_REQUEST = 'LOAD_OVERVIEW_REQUEST';
export const LOAD_OVERVIEW_SUCCESS = 'LOAD_OVERVIEW_SUCCESS';
export const LOAD_OVERVIEW_FAILURE = 'LOAD_OVERVIEW_FAILURE';

export const CHECK_MONITORING_REQUEST = 'CHECK_MONITORING_REQUEST';
export const CHECK_MONITORING_SUCCESS = 'CHECK_MONITORING_SUCCESS';
export const CHECK_MONITORING_FAILURE = 'CHECK_MONITORING_FAILURE';

export const DELETE_SEQUENCE_REQUEST = 'DELETE_SEQUENCE_REQUEST';
export const DELETE_SEQUENCE_SUCCESS = 'DELETE_SEQUENCE_SUCCESS';
export const DELETE_SEQUENCE_FAILURE = 'DELETE_SEQUENCE_FAILURE';

export const UPDATE_DOCUMENT_REQUEST = 'UPDATE_DOCUMENT_REQUEST';
export const UPDATE_DOCUMENT_SUCCESS = 'UPDATE_DOCUMENT_SUCCESS';
export const UPDATE_DOCUMENT_FAILURE = 'UPDATE_DOCUMENT_FAILURE';

export const UPDATE_PSQ_REQUEST = 'UPDATE_PSQ_REQUEST';
export const UPDATE_PSQ_SUCCESS = 'UPDATE_PSQ_SUCCESS';
export const UPDATE_PSQ_FAILURE = 'UPDATE_PSQ_FAILURE';

export const CLEAR_SUBJECT_REQUEST = 'CLEAR_SUBJECT_REQUEST';
export const CLEAR_SUBJECT_SUCCESS = 'CLEAR_SUBJECT_SUCCESS';
export const CLEAR_SUBJECT_FAILURE = 'CLEAR_SUBJECT_FAILURE';

export const EDIT_SUBJECT_REQUEST = 'EDIT_SUBJECT_REQUEST';
export const EDIT_SUBJECT_SUCCESS = 'EDIT_SUBJECT_SUCCESS';
export const EDIT_SUBJECT_FAILURE = 'EDIT_SUBJECT_FAILURE';

export const EDIT_VISIT_REQUEST = 'EDIT_VISIT_REQUEST';
export const EDIT_VISIT_SUCCESS = 'EDIT_VISIT_SUCCESS';
export const EDIT_VISIT_FAILURE = 'EDIT_VISIT_FAILURE';

export const DELETE_IMAGE_REQUEST = 'DELETE_IMAGE_REQUEST';
export const DELETE_IMAGE_SUCCESS = 'DELETE_IMAGE_SUCCESS';
export const DELETE_IMAGE_FAILURE = 'DELETE_IMAGE_FAILURE';

export const DOWNLOAD_AUDIT_REQUEST = 'DOWNLOAD_AUDIT_REQUEST';
export const DOWNLOAD_AUDIT_SUCCESS = 'DOWNLOAD_AUDIT_SUCCESS';
export const DOWNLOAD_AUDIT_FAILURE = 'DOWNLOAD_AUDIT_FAILURE';

export const GET_MOCK_DATASET_REQUEST = 'GET_MOCK_DATASET_REQUEST';
export const GET_MOCK_DATASET_SUCCESS = 'GET_MOCK_DATASET_SUCCESS';
export const GET_MOCK_DATASET_FAILURE = 'GET_MOCK_DATASET_FAILURE';

export const GET_MOCK_LIST_REQUEST = 'GET_MOCK_LIST_REQUEST';
export const GET_MOCK_LIST_SUCCESS = 'GET_MOCK_LIST_SUCCESS';
export const GET_MOCK_LIST_FAILURE = 'GET_MOCK_LIST_FAILURE';

export const SET_MOCK_REQUEST = 'SET_MOCK_REQUEST';
export const SET_MOCK_SUCCESS = 'SET_MOCK_SUCCESS';
export const SET_MOCK_FAILURE = 'SET_MOCK_FAILURE';

export const DOWNLOAD_MOCK_REQUEST = 'DOWNLOAD_MOCK_REQUEST';
export const DOWNLOAD_MOCK_SUCCESS = 'DOWNLOAD_MOCK_SUCCESS';
export const DOWNLOAD_MOCK_FAILURE = 'DOWNLOAD_MOCK_FAILURE';

export const GET_TODO_REQUEST = 'GET_TODO_REQUEST';
export const GET_TODO_SUCCESS = 'GET_TODO_SUCCESS';
export const GET_TODO_FAILURE = 'GET_TODO_FAILURE';

export const ADD_TODO_REQUEST = 'ADD_TODO_REQUEST';
export const ADD_TODO_SUCCESS = 'ADD_TODO_SUCCESS';
export const ADD_TODO_FAILURE = 'ADD_TODO_FAILURE';

export const EXPORT_TODO_REQUEST = 'EXPORT_TODO_REQUEST';
export const EXPORT_TODO_SUCCESS = 'EXPORT_TODO_SUCCESS';
export const EXPORT_TODO_FAILURE = 'EXPORT_TODO_FAILURE';

export const UPDATE_ROLE_REQUEST = 'UPDATE_ROLE_REQUEST';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_FAILURE = 'UPDATE_ROLE_FAILURE';

export const GET_APPROVE_REQUEST = 'GET_APPROVE_REQUEST';
export const GET_APPROVE_SUCCESS = 'GET_APPROVE_SUCCESS';
export const GET_APPROVE_FAILURE = 'GET_APPROVE_FAILURE';

export const createProjectAction = (data) => {
    return {
        type: CREATE_PROJECT_REQUEST,
        data,
    }
};

export const editProjectAction = (data, setVisible) => {
    return {
        type: EDIT_PROJECT_REQUEST,
        data, setVisible,
    }
};

export const deleteProjectAction = (data) => {
    return {
        type: DELETE_PROJECT_REQUEST,
        data,
    }
};

export const loadProjectAction = () => {
    return {
        type: LOAD_PROJECT_REQUEST,
    }
};

export const getOneProjectAction = (projectID) => {
    return {
        type: GET_ONE_PROJECT_REQUEST,
        projectID
    }
};

export const selectSubjectAction = (data, onlyGetList) => {
    return {
        type: SELECT_SUBJECT_REQUEST,
        data, onlyGetList
    }
};

export const searchProjectAction = (data) => {
    return {
        type: SEARCH_PROJECT_REQUEST,
        data,
    }
};

export const searchSubjectAction = (data) => {
    return {
        type: SEARCH_SUBJECT_REQUEST,
        data,
    }
};

export const searchScanAction = (data) => {
    return {
        type: SEARCH_SCAN_REQUEST,
        data,
    }
};

export const editMyListAction = (data) => {
    return {
        type: EDIT_MYLIST_REQUEST,
        data,
    }
};

export const loadProjectVisitAction = (data, setLoading) => {
    return {
        type: LOAD_PROJECT_VISIT_REQUEST,
        data, setLoading
    }
};

export const loadSubjectAction = (data) => {
    return {
        type: LOAD_SUBJECT_REQUEST,
        data,
    }
};

export const loadOnlySubjectAction = (data) => {
    return {
        type: LOAD_ONLY_SUBJECT_REQUEST,
        data
    }
};

export const loadBaselineAction = (data) => {
    return {
        type: LOAD_BASELINE_REQUEST,
        data,
    }
};

export const loadOverviewAction = (data, history, setIsFinished) => {
    return {
        type: LOAD_OVERVIEW_REQUEST,
        data, history, setIsFinished
    }
};

export const checkMonitoringAction = (data) => {
    return {
        type: CHECK_MONITORING_REQUEST,
        data,
    }
};

export const updateProtocolAction = (id, data, name) => {
    return {
        type: UPDATE_DOCUMENT_REQUEST,
        id, data, name
    }
};

export const updatePSQAction = (id, data, name) => {
    return {
        type: UPDATE_PSQ_REQUEST,
        id, data, name
    }
};

export const clearSubjectAction = () => {
    return {
        type: CLEAR_SUBJECT_REQUEST,
    }
};

export const editSubjectAction = (data, sign, setVisible) => {
    return {
        type: EDIT_SUBJECT_REQUEST,
        data, sign, setVisible
    }
};

export const editVisitAction = (data, sign, setVisible) => {
    return {
        type: EDIT_VISIT_REQUEST,
        data, sign, setVisible
    }
};

export const deleteImageAction = (data, sign, setVisible) => {
    return {
        type: DELETE_IMAGE_REQUEST,
        data, sign, setVisible
    }
};

export const downloadAuditAction = (field, projectPK, projectName, sign, setSpinning, setOpen, date) => {
    return {
        type: DOWNLOAD_AUDIT_REQUEST,
        field, projectPK, projectName, sign, setSpinning, setOpen, date
    }
};

export const getMockDatasetAction = () => {
    return {
        type: GET_MOCK_DATASET_REQUEST,
    }
};


export const getMockListAction = () => {
    return {
        type: GET_MOCK_LIST_REQUEST,
    }
};

export const setMockAction = (data, url, setVisible) => {
    return {
        type: SET_MOCK_REQUEST,
        data, url, setVisible
    }
};

export const downMockAction = (projectPK, protocolNum) => {
    return {
        type: DOWNLOAD_MOCK_REQUEST,
        projectPK, protocolNum
    }
};

export const getTodoAction = (projectPK, setLoading, isAdjudicator) => {
    return {
        type: GET_TODO_REQUEST,
        projectPK, setLoading, isAdjudicator
    }
};

export const addTodoAction = (data, isMail, projectPK) => {
    return {
        type: ADD_TODO_REQUEST,
        data, isMail, projectPK
    }
};

export const exportTodoAction = (sign, projectPK, protocolNum, setOpen, setSpinning) => {
    return {
        type: EXPORT_TODO_REQUEST,
        sign, projectPK, protocolNum, setOpen, setSpinning
    }
};

export const updateRoleAction = (data, setVisible, userID) => {
    return {
        type: UPDATE_ROLE_REQUEST,
        data, setVisible, userID
    }
};

export const getApproveAction = (timepointPK, field) => {
    return {
        type: GET_APPROVE_REQUEST,
        timepointPK, field
    }
}