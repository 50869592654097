import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import axios from "axios";
import * as actionTypes from '../_actions/eligibilityAction';
import { message } from 'antd';

function getEligibilityAPI(action) {
    return axios.get('/eligibility/' + action.subjectID);
}
function* getEligibility(action) {
    const result = yield call(() =>getEligibilityAPI(action));
    try {
        yield put({
            type: actionTypes.GET_ELIGIBILITY_SUCCESS,
            payload: result.data,
        });
    } catch (err) {
        yield put({
            type: actionTypes.GET_ELIGIBILITY_FAILURE,
        })
    }
}
function* watchGetEligibility() {
    yield takeLatest(actionTypes.GET_ELIGIBILITY_REQUEST, getEligibility);
}

function saveEligibilityAPI(action) {
    if(action.sign === undefined) {
        return axios.post('/eligibility/' + action.subjectID, action.data);
    } else {
        const config = {headers: {'TI-DS-ID': action.sign.id, 'TI-DS-PW': action.sign.password, "Content-Type": "multipart/form-data"}};
        return axios.post('/eligibility/' + action.subjectID, action.data, config);
    }
}
function* saveEligibility(action) {
    const result = yield call(() =>saveEligibilityAPI(action));
    if(result.status === 200) {
        message.success({content: 'The report was saved successfully.'}).then();
        setTimeout(() => {
            window.location.reload();
        }, [1000]);
    }
    try {
        yield put({
            type: actionTypes.SAVE_ELIGIBILITY_SUCCESS,
        });
    } catch (err) {
        yield put({
            type: actionTypes.SAVE_ELIGIBILITY_FAILURE,
        })
    }
}
function* watchSaveEligibility() {
    yield takeLatest(actionTypes.SAVE_ELIGIBILITY_REQUEST, saveEligibility);
}

function modifyEligibilityAPI(action) {
    const config = {headers: {'TI-DS-ID': action.sign.id, 'TI-DS-PW': action.sign.password}};
    return axios.post('/eligibility/modify/' + action.subjectID + '/' + action.reportType, action.data, config);
}
function* modifyEligibility(action) {
    const result = yield call(() => modifyEligibilityAPI(action));
    if(result.status === 200) {
        message.success({content: 'The report was modified successfully.'}).then();
        setTimeout(() => {
            window.location.reload();
        }, [1000]);
    }
    try {
        yield put({
            type: actionTypes.MODIFY_ELIGIBILITY_SUCCESS,
        });
    } catch (err) {
        yield put({
            type: actionTypes.MODIFY_ELIGIBILITY_FAILURE,
        })
    }
}
function* watchModifyEligibility() {
    yield takeLatest(actionTypes.MODIFY_ELIGIBILITY_REQUEST, modifyEligibility);
}

function reReviewEligibilityAPI(action) {
    const config = {headers: {'TI-DS-ID': action.sign.id, 'TI-DS-PW': action.sign.password}};
    return axios.post('/eligibility/ma/modify/' + action.subjectID + '/' + action.reportType, action.data, config);
}
function* reReviewEligibility(action) {
    const result = yield call(() => reReviewEligibilityAPI(action));
    if(result.status === 200) {
        message.success({content: 'The report was Re-Review successfully.'}).then();
        setTimeout(() => {
            window.location.reload();
        }, [1000]);
    }
    try {
        yield put({
            type: actionTypes.REREVIEW_ELIGIBILITY_SUCCESS,
        });
    } catch (err) {
        yield put({
            type: actionTypes.REREVIEW_ELIGIBILITY_FAILURE,
        })
    }
}
function* watchReReviewEligibility() {
    yield takeLatest(actionTypes.REREVIEW_ELIGIBILITY_REQUEST, reReviewEligibility);
}

function getEligibilityAuditAPI(action) {
    return axios.get('/audit-eligibility/' + action.subjectID);
}
function* getEligibilityAudit(action) {
    try {
        const result = yield call(() => getEligibilityAuditAPI(action));
        yield put({
            type: actionTypes.GET_ELIGIBILITY_AUDIT_SUCCESS,
            payload: result.data
        });
    } catch (err) {
        yield put({
            type: actionTypes.GET_ELIGIBILITY_AUDIT_FAILURE,
        })
    }
}
function* watchGetEligibilityAudit() {
    yield takeLatest(actionTypes.GET_ELIGIBILITY_AUDIT_REQUEST, getEligibilityAudit);
}

export default function* loginSaga() {
    yield all([
        fork(watchGetEligibility),
        fork(watchSaveEligibility),
        fork(watchModifyEligibility),
        fork(watchReReviewEligibility),
        fork(watchGetEligibilityAudit),
    ])
}