import React from 'react';

import { uploadZipAction } from '../../../../../_actions/uploadAction';

const UploadZip = (data, sign, projectID, functions, index) => {
    if(data.fileList.length > 0)
        functions.dispatch(uploadZipAction(data, sign, projectID, functions, index));
};

export default UploadZip;
