export const GET_ELIGIBILITY_REQUEST = 'GET_ELIGIBILITY_REQUEST';
export const GET_ELIGIBILITY_SUCCESS = 'GET_ELIGIBILITY_SUCCESS';
export const GET_ELIGIBILITY_FAILURE = 'GET_ELIGIBILITY_FAILURE';

export const SAVE_ELIGIBILITY_REQUEST = 'SAVE_ELIGIBILITY_REQUEST';
export const SAVE_ELIGIBILITY_SUCCESS = 'SAVE_ELIGIBILITY_SUCCESS';
export const SAVE_ELIGIBILITY_FAILURE = 'SAVE_ELIGIBILITY_FAILURE';

export const MODIFY_ELIGIBILITY_REQUEST = 'MODIFY_ELIGIBILITY_REQUEST';
export const MODIFY_ELIGIBILITY_SUCCESS = 'MODIFY_ELIGIBILITY_SUCCESS';
export const MODIFY_ELIGIBILITY_FAILURE = 'MODIFY_ELIGIBILITY_FAILURE';

export const REREVIEW_ELIGIBILITY_REQUEST = 'REREVIEW_ELIGIBILITY_REQUEST';
export const REREVIEW_ELIGIBILITY_SUCCESS = 'REREVIEW_ELIGIBILITY_SUCCESS';
export const REREVIEW_ELIGIBILITY_FAILURE = 'REREVIEW_ELIGIBILITY_FAILURE';

export const GET_ELIGIBILITY_AUDIT_REQUEST = 'GET_ELIGIBILITY_AUDIT_REQUEST';
export const GET_ELIGIBILITY_AUDIT_SUCCESS = 'GET_ELIGIBILITY_AUDIT_SUCCESS';
export const GET_ELIGIBILITY_AUDIT_FAILURE = 'GET_ELIGIBILITY_AUDIT_FAILURE';

export const getEligibilityAction = (subjectID) => {
    return {
        type: GET_ELIGIBILITY_REQUEST,
        subjectID
    }
};

export const saveEligibilityAction = (subjectID, data, sign) => {
    return {
        type: SAVE_ELIGIBILITY_REQUEST,
        subjectID, data, sign
    }
};

export const modifyEligibilityAction = (subjectID, reportType, sign, data) => {
    return {
        type: MODIFY_ELIGIBILITY_REQUEST,
        subjectID, reportType, sign, data
    }
};

export const reReviewEligibilityAction = (subjectID, reportType, sign, data) => {
    return {
        type: REREVIEW_ELIGIBILITY_REQUEST,
        subjectID, reportType, sign, data
    }
};

export const getEligibilityAuditAction = (subjectID) => {
    return {
        type: GET_ELIGIBILITY_AUDIT_REQUEST,
        subjectID
    }
};