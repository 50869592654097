export const LOAD_REPORT_REQUEST = 'LOAD_REPORT_REQUEST';
export const LOAD_REPORT_SUCCESS = 'LOAD_REPORT_SUCCESS';
export const LOAD_REPORT_FAILURE = 'LOAD_REPORT_FAILURE';

export const LOAD_REPORT_INFO_REQUEST = 'LOAD_REPORT_INFO_REQUEST';
export const LOAD_REPORT_INFO_SUCCESS = 'LOAD_REPORT_INFO_SUCCESS';
export const LOAD_REPORT_INFO_FAILURE = 'LOAD_REPORT_INFO_FAILURE';

export const LOAD_MULTI_REQUEST = 'LOAD_MULTI_REQUEST';
export const LOAD_MULTI_SUCCESS = 'LOAD_MULTI_SUCCESS';
export const LOAD_MULTI_FAILURE = 'LOAD_MULTI_FAILURE';

export const SAVE_REPORT_REQUEST = 'SAVE_REPORT_REQUEST';
export const SAVE_REPORT_SUCCESS = 'SAVE_REPORT_SUCCESS';
export const SAVE_REPORT_FAILURE = 'SAVE_REPORT_FAILURE';

export const MODIFY_REPORT_REQUEST = 'MODIFY_REPORT_REQUEST';
export const MODIFY_REPORT_SUCCESS = 'MODIFY_REPORT_SUCCESS';
export const MODIFY_REPORT_FAILURE = 'MODIFY_REPORT_FAILURE';

export const REVIEW_RECIST_REQUEST = 'REVIEW_RECIST_REQUEST';
export const REVIEW_RECIST_SUCCESS = 'REVIEW_RECIST_SUCCESS';
export const REVIEW_RECIST_FAILURE = 'REVIEW_RECIST_FAILURE';

export const REVIEW_TP_REQUEST = 'REVIEW_TP_REQUEST';
export const REVIEW_TP_SUCCESS = 'REVIEW_TP_SUCCESS';
export const REVIEW_TP_FAILURE = 'REVIEW_TP_FAILURE';

export const REVIEW_MA_REQUEST = 'REVIEW_MA_REQUEST';
export const REVIEW_MA_SUCCESS = 'REVIEW_MA_SUCCESS';
export const REVIEW_MA_FAILURE = 'REVIEW_MA_FAILURE';

export const LOAD_REPORT_AUDIT_REQUEST = 'LOAD_REPORT_AUDIT_REQUEST';
export const LOAD_REPORT_AUDIT_SUCCESS = 'LOAD_REPORT_AUDIT_SUCCESS';
export const LOAD_REPORT_AUDIT_FAILURE = 'LOAD_REPORT_AUDIT_FAILURE';

export const LOAD_MULTI_AUDIT_REQUEST = 'LOAD_MULTI_AUDIT_REQUEST';
export const LOAD_MULTI_AUDIT_SUCCESS = 'LOAD_MULTI_AUDIT_SUCCESS';
export const LOAD_MULTI_AUDIT_FAILURE = 'LOAD_MULTI_AUDIT_FAILURE';

export const LOAD_TP_AUDIT_REQUEST = 'LOAD_TP_AUDIT_REQUEST';
export const LOAD_TP_AUDIT_SUCCESS = 'LOAD_TP_AUDIT_SUCCESS';
export const LOAD_TP_AUDIT_FAILURE = 'LOAD_TP_AUDIT_FAILURE';

export const PICK_REPORT_REQUEST = 'PICK_REPORT_REQUEST';
export const PICK_REPORT_SUCCESS = 'PICK_REPORT_SUCCESS';
export const PICK_REPORT_FAILURE = 'PICK_REPORT_FAILURE';

export const PICK_INFO_REQUEST = 'PICK_INFO_REQUEST';
export const PICK_INFO_SUCCESS = 'PICK_INFO_SUCCESS';
export const PICK_INFO_FAILURE = 'PICK_INFO_FAILURE';

export const LOAD_TYPE_REQUEST = 'LOAD_TYPE_REQUEST';
export const LOAD_TYPE_SUCCESS = 'LOAD_TYPE_SUCCESS';
export const LOAD_TYPE_FAILURE = 'LOAD_TYPE_FAILURE';

export const GET_CHART_REQUEST = 'GET_CHART_REQUEST';
export const GET_CHART_SUCCESS = 'GET_CHART_SUCCESS';
export const GET_CHART_FAILURE = 'GET_CHART_FAILURE';

export const GET_CUSTOM_MULTI_REQUEST = 'GET_CUSTOM_MULTI_REQUEST';
export const GET_CUSTOM_MULTI_SUCCESS = 'GET_CUSTOM_MULTI_SUCCESS';
export const GET_CUSTOM_MULTI_FAILURE = 'GET_CUSTOM_MULTI_FAILURE';

export const GET_CUSTOM_MULTIBUTTON_REQUEST = 'GET_CUSTOM_MULTIBUTTON_REQUEST';
export const GET_CUSTOM_MULTIBUTTON_SUCCESS = 'GET_CUSTOM_MULTIBUTTON_SUCCESS';
export const GET_CUSTOM_MULTIBUTTON_FAILURE = 'GET_CUSTOM_MULTIBUTTON_FAILURE';

export const SAVE_CUSTOM_MULTI_REQUEST = 'SAVE_CUSTOM_MULTI_REQUEST';
export const SAVE_CUSTOM_MULTI_SUCCESS = 'SAVE_CUSTOM_MULTI_SUCCESS';
export const SAVE_CUSTOM_MULTI_FAILURE = 'SAVE_CUSTOM_MULTI_FAILURE';

export const REREVIEW_CUSTOM_MULTI_REQUEST = 'REREVIEW_CUSTOM_MULTI_REQUEST';
export const REREVIEW_CUSTOM_MULTI_SUCCESS = 'REREVIEW_CUSTOM_MULTI_SUCCESS';
export const REREVIEW_CUSTOM_MULTI_FAILURE = 'REREVIEW_CUSTOM_MULTI_FAILURE';

export const loadReportAction = (timepointID, readerIndex, reportType) => {
    return {
        type: LOAD_REPORT_REQUEST,
        timepointID, readerIndex, reportType
    }
};

export const loadReportInfoAction = () => {
    return {
        type: LOAD_REPORT_INFO_REQUEST,
    }
};

export const loadMultiReportAction = (subjectID, reportType) => {
    return {
        type: LOAD_MULTI_REQUEST,
        subjectID, reportType
    }
};

export const saveReportAction = (data, timepointID, readerIndex, reportType, projectID) => {
    return {
        type: SAVE_REPORT_REQUEST,
        data, timepointID, readerIndex, reportType, projectID
    }
};

export const modifyReportAction = (data, timepointID, readerIndex, reportType, setVisible) => {
    return {
        type: MODIFY_REPORT_REQUEST,
        data, timepointID, readerIndex, reportType, setVisible
    }
};

export const reviewRecistAction = (data, setVisible) => {
    return {
        type: REVIEW_RECIST_REQUEST,
        data, setVisible
    }
};

export const reviewTPAction = (data, setVisible) => {
    return {
        type: REVIEW_TP_REQUEST,
        data, setVisible
    }
};

export const reviewMaAction = (data, setVisible) => {
    return {
        type: REVIEW_MA_REQUEST,
        data, setVisible
    }
};

export const loadReportAuditAction = (timepointID, readerIndex, reportType) => {
    return {
        type: LOAD_REPORT_AUDIT_REQUEST,
        timepointID, readerIndex, reportType,
    }
};

export const loadMultiAuditAction = (subjectID) => {
    return {
        type: LOAD_MULTI_AUDIT_REQUEST,
        subjectID
    }
};

export const loadTPAuditAction = (timepointID) => {
    return {
        type: LOAD_TP_AUDIT_REQUEST,
        timepointID
    }
};

export const pickReportAction = (data) => {
    return {
        type: PICK_REPORT_REQUEST,
        data,
    }
};

export const pickInfoAction = (data) => {
    return {
        type: PICK_INFO_REQUEST,
        data,
    }
};

export const loadTypeAction = () => {
    return {
        type: LOAD_TYPE_REQUEST,
    }
};

export const getChartAction = (projectPK, setIsFinished) => {
    return {
        type: GET_CHART_REQUEST,
        projectPK, setIsFinished
    }
};

export const getCustomMultiAction = (timepointPK) => {
    return {
        type: GET_CUSTOM_MULTI_REQUEST,
        timepointPK
    }
};

export const getCustomMultiButtonAction = (timepointPK) => {
    return {
        type: GET_CUSTOM_MULTIBUTTON_REQUEST,
        timepointPK
    }
};

export const saveCustomMultiAction = (timepointPK, data, sign) => {
    return {
        type: SAVE_CUSTOM_MULTI_REQUEST,
        timepointPK, data, sign
    }
};

export const reReReviewCustomMultiAction = (timepointPK, data) => {
    return {
        type: REREVIEW_CUSTOM_MULTI_REQUEST,
        timepointPK, data
    }
};