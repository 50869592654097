import * as actionTypes from '../_actions/userAction';
import { message } from 'antd';

export const initialState = {
    userList: [],
    userSearchResultList: [],
    roleList: [],
    siteInfo: {},
};

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.LOAD_USER_REQUEST :
            return {
                ...state,
            };
        case actionTypes.LOAD_USER_SUCCESS :
            return {
                ...state,
                userList: [...action.payload],
            };
        case actionTypes.LOAD_USER_FAILURE :
            return {
                ...state,
            };

        case actionTypes.CREATE_USER_REQUEST :
            return {
                ...state,
            };
        case actionTypes.CREATE_USER_SUCCESS :
            return {
                ...state,
            };
        case actionTypes.CREATE_USER_FAILURE :
            return {
                ...state,
            };

        case actionTypes.EDIT_USER_REQUEST :
            return {
                ...state,
            };
        case actionTypes.EDIT_USER_SUCCESS :
            message.success({content: '권한이 변경되었습니다.', className: 'Success'}).then();
            return {
                ...state,
            };
        case actionTypes.EDIT_USER_FAILURE :
            return {
                ...state,
            };

        case actionTypes.DELETE_USER_REQUEST :
            return {
                ...state,
            };
        case actionTypes.DELETE_USER_SUCCESS :
            message.success({content: 'User deletion successful', className: 'Success'}).then();
            return {
                ...state,
                userList: [...state.userList.filter(v => v !== action.payload)],
            };
        case actionTypes.DELETE_USER_FAILURE :
            return {
                ...state,
            };

        case actionTypes.LOAD_USER_IN_PROJECT_REQUEST :
            return {
                ...state,
            };
        case actionTypes.LOAD_USER_IN_PROJECT_SUCCESS :
            return {
                ...state,
            };
        case actionTypes.LOAD_USER_IN_PROJECT_FAILURE :
            return {
                ...state,
            };

        case actionTypes.SEARCH_USER_REQUEST :
            return {
                ...state,
            };
        case actionTypes.SEARCH_USER_SUCCESS :
            return {
                ...state,
                userSearchResultList: [...action.payload],
            };
        case actionTypes.SEARCH_USER_FAILURE :
            return {
                ...state,
            };

        case actionTypes.LOAD_ROLE_REQUEST :
            return {
                ...state,
            };
        case actionTypes.LOAD_ROLE_SUCCESS :
            return {
                ...state,
                roleList: [...action.payload],
            };
        case actionTypes.LOAD_ROLE_FAILURE :
            return {
                ...state,
            };

        case actionTypes.SEND_EMAIL_REQUEST :
            return {
                ...state,
            };
        case actionTypes.SEND_EMAIL_SUCCESS :
            return {
                ...state,
            };
        case actionTypes.SEND_EMAIL_FAILURE :
            return {
                ...state,
            };

        case actionTypes.LOAD_SITE_INFO_REQUEST :
            return {
                ...state,
            };
        case actionTypes.LOAD_SITE_INFO_SUCCESS :
            return {
                ...state,
                siteInfo: action.payload,
            };
        case actionTypes.LOAD_SITE_INFO_FAILURE :
            return {
                ...state,
            };

        case actionTypes.REGISTRATION_USER_REQUEST :
            return {
                ...state,
            };
        case actionTypes.REGISTRATION_USER_SUCCESS :
            window.location.href = window.location.origin;
            return {
                ...state,
            };
        case actionTypes.REGISTRATION_USER_FAILURE :
            return {
                ...state,
            };

        default:
            return {
                ...state
            };
    }
};

export default reducer;