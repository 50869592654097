import { combineReducers } from 'redux';

import auth from './auth';
import login from './log';
import project from './project';
import user from './user';
import report from './report';
import qc from './qc';
import query from './query';
import upload from './upload';
import site from './site';
import dm from './dm';
import eligibility from './eligibility';

const rootReducer = combineReducers({ auth, login, project, user, report, qc, query, upload, site, dm, eligibility });

export default rootReducer;