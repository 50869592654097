export const LOAD_USER_REQUEST = 'LOAD_USER_REQUEST';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const LOAD_USER_FAILURE = 'LOAD_USER_FAILURE';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const LOAD_USER_IN_PROJECT_REQUEST = 'LOAD_USER_IN_PROJECT_REQUEST';
export const LOAD_USER_IN_PROJECT_SUCCESS = 'LOAD_USER_IN_PROJECT_SUCCESS';
export const LOAD_USER_IN_PROJECT_FAILURE = 'LOAD_USER_IN_PROJECT_FAILURE';

export const SEARCH_USER_REQUEST = 'SEARCH_USER_REQUEST';
export const SEARCH_USER_SUCCESS = 'SEARCH_USER_SUCCESS';
export const SEARCH_USER_FAILURE = 'SEARCH_USER_FAILURE';

export const LOAD_ROLE_REQUEST = 'LOAD_ROLE_REQUEST';
export const LOAD_ROLE_SUCCESS = 'LOAD_ROLE_SUCCESS';
export const LOAD_ROLE_FAILURE = 'LOAD_ROLE_FAILURE';

export const SEND_EMAIL_REQUEST = 'SEND_EMAIL_REQUEST';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAILURE = 'SEND_EMAIL_FAILURE';

export const LOAD_SITE_INFO_REQUEST = 'LOAD_SITE_INFO_REQUEST';
export const LOAD_SITE_INFO_SUCCESS = 'LOAD_SITE_INFO_SUCCESS';
export const LOAD_SITE_INFO_FAILURE = 'LOAD_SITE_INFO_FAILURE';

export const REGISTRATION_USER_REQUEST = 'REGISTRATION_USER_REQUEST';
export const REGISTRATION_USER_SUCCESS = 'REGISTRATION_USER_SUCCESS';
export const REGISTRATION_USER_FAILURE = 'REGISTRATION_USER_FAILURE';

export const loadUserAction = () => {
    return {
        type: LOAD_USER_REQUEST,
    }
};

export const createUserAction = (data, isSpinning) => {
    return {
        type: CREATE_USER_REQUEST,
        data, isSpinning,
    }
}

export const deleteUserAction = (data) => {
    return {
        type: DELETE_USER_REQUEST,
        data,
    }
};

export const loadUserInProjectAction = (data, setUserList) => {
    return {
        type: LOAD_USER_IN_PROJECT_REQUEST,
        data,
        setUserList,
    }
};

export const searchUserAction = (data) => {
    return {
        type: SEARCH_USER_REQUEST,
        data,
    }
}

export const loadRoleAction = () => {
    return {
        type: LOAD_ROLE_REQUEST,
    }
}

export const sendEmailAction = (data, isSpinning) => {
    return {
        type: SEND_EMAIL_REQUEST,
        data, isSpinning,
    }
}

export const loadSiteInfoAction = () => {
    return {
        type: LOAD_SITE_INFO_REQUEST,
    }
}

export const registrationUserAction = (data) => {
    return {
        type: REGISTRATION_USER_REQUEST,
        data,
    }
};