import * as actionTypes from '../_actions/authAction';
import { message } from 'antd';

export const initialState = {
    auth: '',
    info: {},
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_REQUEST :
            return {
                ...state,
            };
        case actionTypes.LOGIN_SUCCESS :
            return {
                ...state,
            };
        case actionTypes.LOGIN_FAILURE :
            return {
                ...state,
            };

        case actionTypes.LOGOUT_REQUEST :
            return {
                ...state,
            };
        case actionTypes.LOGOUT_SUCCESS :
            return {
                ...state,
                auth: '',
                info: {},
            };
        case actionTypes.LOGOUT_FAILURE :
            return {
                ...state,
            };

        case actionTypes.AUTH_REQUEST :
            return {
                ...state,
            };
        case actionTypes.AUTH_SUCCESS :
            return {
                ...state,
                auth: action.auth,
                info: {
                    company: "Trial Informatics", projectID: null, email: "ADMIN", userID: 1, name: "ADMIN", roleIdx: null,
                    actionMap: {
                        upload: true, edit: true,  todoList: true, ma: true, maComment: true,
                        reReview: true, agree: true,
                        protocolDoc: true, charterDoc: true, maDoc: true,
                        monitoring: {view: true, read: true, write: true},
                        qc: {view: true, read: true, write: true},
                        eligibility: {view: true, read: true, write: true},
                        report: {view: true, read: true, write: false},
                        multiform: {view: true, read: true, write: true},
                    }, ...action.info
                }
            }
        case actionTypes.AUTH_FAILURE :
            return {
                ...state,
                auth: false,
            };

        case actionTypes.REG_AUTH_REQUEST :
            return {
                ...state,
            };
        case actionTypes.REG_AUTH_SUCCESS :
            return {
                ...state,
                auth: action.auth,
            };
        case actionTypes.REG_AUTH_FAILURE :
            return {
                ...state,
                auth: false,
            };

        case actionTypes.REFRESH_TOKEN_REQUEST:
            return {
                ...state
            };
        case actionTypes.REFRESH_TOKEN_SUCCESS :
            return {
                ...state,
            };
        case actionTypes.REFRESH_TOKEN_FAILURE :
            return {
                ...state,
            };

        case actionTypes.CHECK_ACCOUNT_REQUEST:
            return {
                ...state
            };
        case actionTypes.CHECK_ACCOUNT_SUCCESS :
            return {
                ...state,
            };
        case actionTypes.CHECK_ACCOUNT_FAILURE :
            return {
                ...state,
            };

        case actionTypes.CHANGE_INFO_REQUEST:
            return {
                ...state
            };
        case actionTypes.CHANGE_INFO_SUCCESS :
            message.success({ content: 'Change Information Completed'}).then();
            action.setVisible(false);
            return {
                ...state,
                info: {...state.info, name: action.payload.name}
            };
        case actionTypes.CHANGE_INFO_FAILURE :
            return {
                ...state,
            };

        case actionTypes.FORGOT_PASSWORD_REQUEST:
            return {
                ...state
            };
        case actionTypes.FORGOT_PASSWORD_SUCCESS :
            message.success({ content: 'Completed sending temporary password by email'}).then();
            action.setVisible(false);
            return {
                ...state,
            };
        case actionTypes.FORGOT_PASSWORD_FAILURE :
            return {
                ...state,
            };
        default:
            return {
                ...state
            };
    }
};

export default reducer;