import * as actionTypes from '../_actions/projectAction';
import { message } from 'antd';

export const initialState = {
    projectList: [],
    subjectList: [],
    visitList: [],
    myList: [],

    selectedProject: {},
    selectedSubject: null,

    projectSearchResultList: [],
    subjectSearchResultList: [],
    visitSearchResultList: [],

    projectSearchState: false,
    subjectSearchState: false,
    visitSearchState: false,

    siteData: [],
    qcPieData: [],
    monitoringPieData: [],
    queryPieData: [],
    qcTableData: [],
    monitoringTableData: [],
    queryTableData: [],

    mockList: [],
    mockDataset: [],

    todoSubjectList: [],
    todoVisitList: [],

    approveAudit: [],

    baselinePK: null,
    baseline: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CREATE_PROJECT_REQUEST :
            return {
                ...state,
            };
        case actionTypes.CREATE_PROJECT_SUCCESS :
            message.success({ content: 'Project Created'}).then();
            // window.location.href = '/admin';
            return {
                ...state,
                projectList: [...action.payload],
            };
        case actionTypes.CREATE_PROJECT_FAILURE :
            message.error({ content: 'Duplicated'}).then();
            return {
                ...state,
            };

        case actionTypes.EDIT_PROJECT_REQUEST :
            return {
                ...state,
            };
        case actionTypes.EDIT_PROJECT_SUCCESS :
            message.success({ content: 'Project modification is complete'}).then();
            return {
                ...state,
                projectList: state.projectList.map(function(v) {
                    if(v.id === action.payload.id)
                        return action.payload;
                    else
                        return v;
                }),
                selectedProject: action.payload
            };
        case actionTypes.EDIT_PROJECT_FAILURE :
            message.error({ content: 'Failed to modify project. Please check the information you entered'}).then();
            return {
                ...state,
            };

        case actionTypes.DELETE_PROJECT_REQUEST :
            return {
                ...state,
            };
        case actionTypes.DELETE_PROJECT_SUCCESS :
            message.success({content: 'Project Deleted', className: 'Success'}).then();
            return {
                ...state,
                projectList: [...state.projectList.filter(v => v !== action.payload)],
            };
        case actionTypes.DELETE_PROJECT_FAILURE :
            return {
                ...state,
            };

        case actionTypes.LOAD_PROJECT_REQUEST :
            return {
                ...state,
            };
        case actionTypes.LOAD_PROJECT_SUCCESS :
            if(action.info['newUserHasProjectList'].length > 0) {
                for(let i = 0; i < action.info['newUserHasProjectList'].length; i++) {
                    for(let j = 0; j < action.payload.length; j ++) {
                        if(action.info['newUserHasProjectList'][i]['projectID'] === action.payload[j].id) {
                            action.payload[j].bookmark = action.info['newUserHasProjectList'][i]['bookmark'] === true;
                        }
                    }
                }
            }
            return {
                ...state,
                projectList: [...action.payload],
                myList: [...action.payload.filter(v => v.bookmark === true)]
            };
        case actionTypes.LOAD_PROJECT_FAILURE :
            return {
                ...state,
            };

        case actionTypes.GET_ONE_PROJECT_REQUEST :
            return {
                ...state,
            };
        case actionTypes.GET_ONE_PROJECT_SUCCESS :
            return {
                ...state,
                selectedProject: action.payload.filter(v => v.id === action.projectID)[0],
                projectList: [...action.payload],
            };
        case actionTypes.GET_ONE_PROJECT_FAILURE :
            return {
                ...state,
            };

        case actionTypes.SELECT_SUBJECT_REQUEST :
            return {
                ...state,
            };
        case actionTypes.SELECT_SUBJECT_SUCCESS :
            return {
                ...state,
                visitList: [...action.payload.sort(function(a, b) {
                    return new Date(a['scanDate']) - new Date(b['scanDate']);
                })],
                selectedSubject: action.result,
            };
        case actionTypes.SELECT_SUBJECT_FAILURE :
            return {
                ...state,
            };
        case actionTypes.SEARCH_PROJECT_REQUEST :
            return {
                ...state,
            };
        case actionTypes.SEARCH_PROJECT_SUCCESS :
            return {
                ...state,
                projectSearchResultList: [...action.payload],
            };
        case actionTypes.SEARCH_PROJECT_FAILURE :
            return {
                ...state,
            };

        case actionTypes.SEARCH_SUBJECT_REQUEST :
            return {
                ...state,
            };
        case actionTypes.SEARCH_SUBJECT_SUCCESS :
            return {
                ...state,
                subjectSearchResultList: [...action.payload],
                subjectSearchState: true,
            };
        case actionTypes.SEARCH_SUBJECT_FAILURE :
            return {
                ...state,
            };

        case actionTypes.SEARCH_SCAN_REQUEST :
            return {
                ...state,
            };
        case actionTypes.SEARCH_SCAN_SUCCESS :
            return {
                ...state,
                visitSearchResultList: [...action.payload],
                visitSearchState: true,
            };
        case actionTypes.SEARCH_SCAN_FAILURE :
            return {
                ...state,
            };

        case actionTypes.EDIT_MYLIST_REQUEST :
            return {
                ...state,
            };
        case actionTypes.EDIT_MYLIST_SUCCESS :
            if(action.info['newUserHasProjectList'].length > 0) {
                for(let i = 0; i < action.info['newUserHasProjectList'].length; i++) {
                    for(let j = 0; j < state.projectList.length; j ++) {
                        if(action.info['newUserHasProjectList'][i]['projectID'] === state.projectList[j].id) {
                            state.projectList[j].bookmark = action.info['newUserHasProjectList'][i]['bookmark'] === true;
                        }
                    }
                }
            }
            return {
                ...state,
                myList: [...state.projectList.filter(v => v.bookmark === true)],
                projectList: [...state.projectList]
            };
        case actionTypes.EDIT_MYLIST_FAILURE :
            return {
                ...state,
            };

        case actionTypes.LOAD_PROJECT_VISIT_REQUEST :
            return {
                ...state,
            };
        case actionTypes.LOAD_PROJECT_VISIT_SUCCESS :
            if(window.location.pathname === '/overview') {
                return {
                    ...state,
                    visitList: [...action.payload],
                }
            } else {
                return {
                    ...state,
                    visitList: [...action.payload],
                }
            }
        case actionTypes.LOAD_PROJECT_VISIT_FAILURE :
            return {
                ...state,
            };

        case actionTypes.LOAD_SUBJECT_REQUEST :
            return {
                ...state,
            };
        case actionTypes.LOAD_SUBJECT_SUCCESS :
            return {
                ...state,
                subjectList: [...action.payload],
                showSubject: true,
                visitList: [],
            };
        case actionTypes.LOAD_SUBJECT_FAILURE :
            return {
                ...state,
            };
        case actionTypes.LOAD_ONLY_SUBJECT_REQUEST :
            return {
                ...state,
            };
        case actionTypes.LOAD_ONLY_SUBJECT_SUCCESS :
            return {
                ...state,
                subjectList: [...action.payload],
            };
        case actionTypes.LOAD_ONLY_SUBJECT_FAILURE :
            return {
                ...state,
            };
        case actionTypes.LOAD_BASELINE_REQUEST :
            return {
                ...state,
            };
        case actionTypes.LOAD_BASELINE_SUCCESS :
            let pk;
            let bl;
            action.payload.map(v => {
                if(v.timePoint === 'BL') {
                    pk = v.id;
                    bl = v;
                }
            });
            return {
                ...state,
                baselinePK: pk,
            };
        case actionTypes.LOAD_BASELINE_FAILURE :
            return {
                ...state,
            };
        case actionTypes.LOAD_VISIT_REQUEST :
            return {
                ...state,
            };
        case actionTypes.LOAD_VISIT_SUCCESS :
            return {
                ...state,
                visitList: [...action.payload],
                selectedSubject: null,
            };
        case actionTypes.LOAD_VISIT_FAILURE :
            return {
                ...state,
            };

        case actionTypes.LOAD_OVERVIEW_REQUEST :
            return {
                ...state,
            };
        case actionTypes.LOAD_OVERVIEW_SUCCESS :
            action.setIsFinished(true);
            return {
                ...state,
                siteData: [...action.siteData],
                qcPieData: [...action.qcPieData],
                monitoringPieData: [...action.monitoringPieData],
                queryPieData: [...action.queryPieData],
                qcTableData: [...action.qcTableData],
                monitoringTableData: [...action.monitoringTableData],
                queryTableData: [...action.queryTableData],
            };
        case actionTypes.LOAD_OVERVIEW_FAILURE :
            return {
                ...state,
            };

        case actionTypes.CHECK_MONITORING_REQUEST :
            return {
                ...state,
            };
        case actionTypes.CHECK_MONITORING_SUCCESS :
            const list = state.visitList.map(function(v) {
                if(v.id === action.payload.id) {
                    return {...v, monitoringState: action.payload.monitoringState};
                } else {
                    return v;
                }
            })
            return {
                ...state,
                visitList: [...list]
            };
        case actionTypes.CHECK_MONITORING_FAILURE :
            return {
                ...state,
            };

        case actionTypes.DELETE_SEQUENCE_REQUEST :
            return {
                ...state,
            };
        case actionTypes.DELETE_SEQUENCE_SUCCESS :
            message.success({content: 'Sequence Deleted', className: 'Success'}).then();
            return {
                ...state,
            };
        case actionTypes.DELETE_SEQUENCE_FAILURE :
            return {
                ...state,
            };

        case actionTypes.UPDATE_DOCUMENT_REQUEST :
            return {
                ...state,
            };
        case actionTypes.UPDATE_DOCUMENT_SUCCESS :
            return {
                ...state,
                projectList: [...state.projectList.map(function(v) {
                    if(v.id === action.payload.id) {
                        return {...v, fileList: {...v.fileList, [action.payload.name]: action.payload.data}};
                    } else {
                        return v;
                    }
                })]
            };
        case actionTypes.UPDATE_DOCUMENT_FAILURE :
            return {
                ...state,
            };

        case actionTypes.UPDATE_PSQ_REQUEST :
            return {
                ...state,
            };
        case actionTypes.UPDATE_PSQ_SUCCESS :
            return {
                ...state,
                projectList: [...state.projectList.map(function(v) {
                    if(v.id === action.payload.id) {
                        return {...v, fileList: {...v.fileList, [action.payload.name]: action.payload.data}};
                    } else {
                        return v;
                    }
                })]
            };
        case actionTypes.UPDATE_PSQ_FAILURE :
            return {
                ...state,
            };

        case actionTypes.CLEAR_SUBJECT_REQUEST :
            return {
                ...state,
            };
        case actionTypes.CLEAR_SUBJECT_SUCCESS :
            return {
                ...state,
                subjectList: [],
                visitList: [],
                selectedSubject: null,
            };
        case actionTypes.CLEAR_SUBJECT_FAILURE :
            return {
                ...state,
            };

        case actionTypes.EDIT_SUBJECT_REQUEST :
            return {
                ...state,
            };
        case actionTypes.EDIT_SUBJECT_SUCCESS :
            return {
                ...state,
                subjectList: [...state.subjectList.map(v => {
                    if(v.id === action.payload.id) {
                        return action.payload;
                    } else return v;
                })]
            };
        case actionTypes.EDIT_SUBJECT_FAILURE :
            return {
                ...state,
            };

        case actionTypes.EDIT_VISIT_REQUEST :
            return {
                ...state,
            };
        case actionTypes.EDIT_VISIT_SUCCESS :
            return {
                ...state,
                visitList: [...state.visitList.map(v => {
                    if(v.id === action.payload.id) {
                        return {...v, visit: action.payload.visit};
                    } else return v;
                })]
            };
        case actionTypes.EDIT_VISIT_FAILURE :
            return {
                ...state,
            };

        case actionTypes.DELETE_IMAGE_REQUEST :
            return {
                ...state,
            };
        case actionTypes.DELETE_IMAGE_SUCCESS :
            action.payload.setVisible(false);
            return {
                ...state,
            };
        case actionTypes.DELETE_IMAGE_FAILURE :
            return {
                ...state,
            };

        case actionTypes.DOWNLOAD_AUDIT_REQUEST :
            return {
                ...state,
            };
        case actionTypes.DOWNLOAD_AUDIT_SUCCESS :
            return {
                ...state,
            };
        case actionTypes.DOWNLOAD_AUDIT_FAILURE :
            return {
                ...state,
            };

        case actionTypes.GET_MOCK_DATASET_REQUEST :
            return {
                ...state,
            };
        case actionTypes.GET_MOCK_DATASET_SUCCESS :
            return {
                ...state,
                mockDataset: action.payload,
            };
        case actionTypes.GET_MOCK_DATASET_FAILURE :
            return {
                ...state,
            };

        case actionTypes.GET_MOCK_LIST_REQUEST :
            return {
                ...state,
            };
        case actionTypes.GET_MOCK_LIST_SUCCESS :
            return {
                ...state,
                mockList: action.payload,
            };
        case actionTypes.GET_MOCK_LIST_FAILURE :
            return {
                ...state,
            };

        case actionTypes.SET_MOCK_REQUEST :
            return {
                ...state,
            };
        case actionTypes.SET_MOCK_SUCCESS :
            return {
                ...state,
            };
        case actionTypes.SET_MOCK_FAILURE :
            return {
                ...state,
            };

        case actionTypes.DOWNLOAD_MOCK_REQUEST :
            return {
                ...state,
            };
        case actionTypes.DOWNLOAD_MOCK_SUCCESS :
            return {
                ...state,
            };
        case actionTypes.DOWNLOAD_MOCK_FAILURE :
            return {
                ...state,
            };

        case actionTypes.GET_TODO_REQUEST :
            return {
                ...state,
            };
        case actionTypes.GET_TODO_SUCCESS :
            if(action.isAdjudicator === true) {
                return {
                    ...state,
                    todoSubjectList: [...action.payload],
                    todoVisitList: [],
                };
            } else {
                return {
                    ...state,
                    todoSubjectList: [],
                    todoVisitList: [...action.payload],
                };
            }
        case actionTypes.GET_TODO_FAILURE :
            return {
                ...state,
            };

        case actionTypes.ADD_TODO_REQUEST :
            return {
                ...state,
            };
        case actionTypes.ADD_TODO_SUCCESS :
            message.success({ content: 'Todo List is Updated'}).then();
            return {
                ...state,
            };
        case actionTypes.ADD_TODO_FAILURE :
            return {
                ...state,
            };

        case actionTypes.EXPORT_TODO_REQUEST :
            return {
                ...state,
            };
        case actionTypes.EXPORT_TODO_SUCCESS :
            return {
                ...state,
            };
        case actionTypes.EXPORT_TODO_FAILURE :
            return {
                ...state,
            };

        case actionTypes.UPDATE_ROLE_REQUEST :
            return {
                ...state,
            };
        case actionTypes.UPDATE_ROLE_SUCCESS :
            message.success({ content: 'Project modification is complete'}).then();
            return {
                ...state,
                projectList: state.projectList.map(function(v) {
                    if(v.id === action.payload.id)
                        return action.payload;
                    else
                        return v;
                }),
                selectedProject: action.payload
            };
        case actionTypes.UPDATE_ROLE_FAILURE :
            return {
                ...state,
            };

        case actionTypes.GET_APPROVE_REQUEST :
            return {
                ...state,
            };
        case actionTypes.GET_APPROVE_SUCCESS :
            return {
                ...state,
                approveAudit: [...action.payload],
            };
        case actionTypes.GET_APPROVE_FAILURE :
            return {
                ...state,
            };

        default:
            return {
                ...state
            };
    }
};

export default reducer;