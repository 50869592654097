import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import axios from "axios";
import * as actionTypes from '../_actions/uploadAction';
import UploadZip from '../components/Component/Uploader/ZipFile/Function/UploadZip';

// Upload Dicom ///////////////////////////////////////////////////////////////////////////////////////////////////
function uploadDicomAPI(action) {
    return axios({ method: 'post', url: '/dicom-upload/' + action.projectID, data: action.data, headers: {'TI-DS-ID': action.sign.id, 'TI-DS-PW': action.sign.password, 'Content-Type': 'multipart/form-data'},
        onUploadProgress: progressEvent => {
            const currentArrayLength = action.totalLength - action.uploadLength;
            const step = (100 / action.totalLength).toFixed(0);
            let temp = Math.round(step * currentArrayLength);
            let percent = Math.round((((progressEvent.loaded * 100) / progressEvent.total) / action.totalLength) + temp);
            action.setProgress(percent)
    }});
}
function* uploadDicom(action) {
    try {
        yield call(() => uploadDicomAPI(action));
        yield put({
            type: actionTypes.UPLOAD_DICOM_SUCCESS,
            action: action,
        });
    } catch (err) {
        action.setViewUploadProgress(false);
        action.setIsUpload(false);
        yield put({
            type: actionTypes.UPLOAD_DICOM_FAILURE,
            action: action,
        })
    }
}
function* watchUploadDicom() {
    yield takeLatest(actionTypes.UPLOAD_DICOM_REQUEST, uploadDicom);
}

// Upload Image ///////////////////////////////////////////////////////////////////////////////////////////////////
function uploadImageAPI(action) {
    return axios({ method: 'post', url: '/image-upload/' + action.projectID, data: action.data, headers: {'TI-DS-ID': action.sign.id, 'TI-DS-PW': action.sign.password},
        onUploadProgress: progressEvent => {
            let percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            action.setPercentage(percent)
        }});
}
function* uploadImage(action) {
    try {
        const result = yield call(() => uploadImageAPI(action));
        if (result.status === 200) {
            action.setViewPercentage(false);
            action.setIsUploading(false);
            action.setSuccessModal(true);
        }
        yield put({
            type: actionTypes.UPLOAD_IMAGE_SUCCESS,
            payload: action
        });
    } catch (err) {
        action.setViewPercentage(false);
        action.setIsUploading(false);
        yield put({
            type: actionTypes.UPLOAD_IMAGE_FAILURE,
            action: action,
        })
    }
}
function* watchUploadImage() {
    yield takeLatest(actionTypes.UPLOAD_IMAGE_REQUEST, uploadImage);
}

function getUrlZipAPI(action) {
    return axios.post('/zip-file/get-pre-url/' + action.projectID, {fileName: action.data.fileList[0].fileName, metaData: action.data.metaData['0']},
        {headers: {'TI-DS-ID': action.sign.id, 'TI-DS-PW': action.sign.password}});
}

function uploadZipS3API(action, url) {
    return axios.put(url, action.data.fileList[0].files, {headers: {'TI-DS-ID': action.sign.id, 'TI-DS-PW': action.sign.password}, baseURL: '/',
        onUploadProgress: progressEvent => {
            const currentArrayLength = action.functions.totalLength - action.index;
            const step = (100 / action.functions.totalLength).toFixed(0);
            let temp = Math.round(step * currentArrayLength);
            let percent = Math.round((((progressEvent.loaded * 100) / progressEvent.total) / action.functions.totalLength) + temp);
            setTimeout(() => {
                action.functions.setSortingPercentage(percent);
            }, [2000]);
        }});
}

function makeInfoAPI(action) {
    let metaData = action.data.metaData.find(v => v.subject === action.data.fileList[0].fileName.split('.')[0]);
    return axios.post('/zip-file/success/' + action.projectID, {metaData: metaData}, {headers: {'TI-DS-ID': action.sign.id, 'TI-DS-PW': action.sign.password}});
}
function* uploadZip(action) {
    try {
        const url = yield call(() => getUrlZipAPI(action));
        if(url.status === 200) {
            const s3Result = yield call(() => uploadZipS3API(action, url.data));
            if(s3Result.status === 200) {
                const result = yield call(() => makeInfoAPI(action));
                if(result.status === 200) {
                    yield put({
                        type: actionTypes.UPLOAD_ZIP_SUCCESS,
                        payload: action
                    });
                    if(action.index - 1 === 0) {
                        setTimeout(() => {
                            action.functions.setIsUpload(false);
                            action.functions.setSuccessModal(true);
                        }, [5000]);
                    } else {
                        let index = action.index - 1;
                        let fileList = action.data.fileList;
                        let metaData = action.data.metaData;
                        UploadZip({
                            fileList: fileList.filter(v => v.fileName !== action.data.fileList[0].fileName),
                            metaData: metaData.filter(v => v.subject !== action.data.fileList[0].fileName.split('.')[0])
                        }, action.sign, action.projectID, action.functions, index);
                    }
                }
            }
        }
    } catch (err) {
        action.functions.setViewSortingPercentage(false);
        action.functions.setIsUpload(false);
        action.functions.setDisabled(false);
        yield put({
            type: actionTypes.UPLOAD_ZIP_FAILURE,
            action: action,
        })
    }
}
function* watchUploadZip() {
    yield takeLatest(actionTypes.UPLOAD_ZIP_REQUEST, uploadZip);
}
export default function* uploadSaga() {
    yield all([
        fork(watchUploadDicom),
        fork(watchUploadImage),
        fork(watchUploadZip),
    ])
}