import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import axios from "axios";
import * as actionTypes from '../_actions/authAction';

// Login ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
function loginAPI(data) {
    return axios.post('/login', data);
}

function* login(action) {
    try {
        window.localStorage.removeItem('full');
        window.localStorage.removeItem('move');
        const result = yield call(() => loginAPI(action.data));
        if(result.status === 200) {
            action.setIsLoading(false);
            window.localStorage.setItem('cookie', result.data);
            if(window.localStorage.getItem('page') === null) {
                window.location.href = window.location.origin + '/main';
            } else {
                window.location.href = window.localStorage.getItem('page');
            }
        }
        yield put({
            type: actionTypes.LOGIN_SUCCESS,
        });
    } catch (err) {
        window.localStorage.clear();
        action.setIsLoading(false);
        yield put({
            type: actionTypes.LOGIN_FAILURE,
        });
    }
}
function* watchLogin() {
    yield takeLatest(actionTypes.LOGIN_REQUEST, login);
}

// logout //////////////////////////////////////////////////////////////////////////////////////////////////////////////
function LogoutAPI(data) {
    return axios.post('/logout-jwt', data).then(function() {
        window.localStorage.clear();
        window.location.href = window.location.origin;
    }).catch(function() {
        window.localStorage.clear();
        window.location.href = window.location.origin;
    });
}
function* logout(action) {
    try {
        yield call(() => LogoutAPI(action.data));
        yield put({
            type: actionTypes.LOGOUT_SUCCESS,
        });

    } catch (err) {
        yield put({
            type: actionTypes.LOGOUT_FAILURE,
        });
    }
}
function* watchLogout() {
    yield takeLatest(actionTypes.LOGOUT_REQUEST, logout);
}

// Auth ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function authAPI(data) {
    return axios.post('/chk-jwt', data);
}
function infoAPI() {
    return axios.get('/user/get-info');
}
function* auth(action) {
    try {
        if(action.data === undefined || action.data === '' || action.data === null) {
            action.data = ' ';
        }
        const auth = yield call(() => authAPI(action.data));
        const info = yield call(infoAPI);
        yield put({
            type: actionTypes.AUTH_SUCCESS,
            auth: auth.data,
            info: info.data,
        });

    } catch (err) {
        yield put({
            type: actionTypes.AUTH_FAILURE,
        });
    }
}
function* watchAuth() {
    yield takeLatest(actionTypes.AUTH_REQUEST, auth);
}

// Auth ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function regAuthAPI(data) {
    return axios.post('/chk-jwt', data);
}
function* regAuth(action) {
    try {
        if(action.data === undefined || action.data === '' || action.data === null) {
            action.data = ' ';
        }
        const auth = yield call(() => regAuthAPI(action.data));
        yield put({
            type: actionTypes.REG_AUTH_SUCCESS,
            auth: auth.data,
        });

    } catch (err) {
        yield put({
            type: actionTypes.REG_AUTH_FAILURE,
        });
    }
}

function* watchRegAuth() {
    yield takeLatest(actionTypes.REG_AUTH_REQUEST, regAuth);
}

function refreshTokenAPI() {
    return axios.get('/refresh-token');
}
function* refreshToken() {
    try {
        const result = yield call(refreshTokenAPI);
        if(result.status === 200) {
            window.localStorage.setItem('cookie', result.data);
            axios.defaults.headers['X-AUTH-TOKEN'] = result.data;
        }
        yield put({
            type: actionTypes.REFRESH_TOKEN_SUCCESS,
        });

    } catch (err) {
        yield put({
            type: actionTypes.REFRESH_TOKEN_FAILURE,
        });
    }
}
function* watchRefreshToken() {
    yield takeLatest(actionTypes.REFRESH_TOKEN_REQUEST, refreshToken);
}

function checkAccountAPI(action) {
    return axios.post('/chk-account', action.data);
}
function* checkAccount(action) {
    yield call(() => checkAccountAPI(action));
    try {
        yield put({
            type: actionTypes.CHECK_ACCOUNT_SUCCESS,
        });
    } catch (err) {
        yield put({
            type: actionTypes.CHECK_ACCOUNT_FAILURE,
        });
    }
}
function* watchCheckAccount() {
    yield takeLatest(actionTypes.CHECK_ACCOUNT_REQUEST, checkAccount);
}

function changeInfoAPI(action) {
    return axios.put('/user', action.data);
}
function* changeInfo(action) {
    const result = yield call(() => changeInfoAPI(action));
    try {
        yield put({
            type: actionTypes.CHANGE_INFO_SUCCESS,
            payload: result.data,
            setVisible: action.setVisible,
        });
    } catch (err) {
        yield put({
            type: actionTypes.CHANGE_INFO_FAILURE,
        });
    }
}
function* watchChangeInfo() {
    yield takeLatest(actionTypes.CHANGE_INFO_REQUEST, changeInfo);
}


function forgotPasswordAPI(action) {
    return axios.post('/user/send-temp-pw', action.data);
}
function* forgotPassword(action) {
    yield call(() => forgotPasswordAPI(action));
    try {
        yield put({
            type: actionTypes.FORGOT_PASSWORD_SUCCESS,
            setVisible: action.setVisible,
        });
    } catch (err) {
        yield put({
            type: actionTypes.FORGOT_PASSWORD_FAILURE,
        });
    }
}
function* watchForgotPassword() {
    yield takeLatest(actionTypes.FORGOT_PASSWORD_REQUEST, forgotPassword);
}

export default function* authSaga() {
    yield all([
        fork(watchLogin),
        fork(watchLogout),
        fork(watchAuth),
        fork(watchRegAuth),
        fork(watchRefreshToken),
        fork(watchCheckAccount),
        fork(watchChangeInfo),
        fork(watchForgotPassword),
    ])
}